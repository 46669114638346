import './App.css';
import { ChakraProvider, extendTheme } from "@chakra-ui/react"
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import HomePage from './pages/HomePage';

const config = {
  initialColorMode: "dark",
  useSystemColorMode: false,
}
const theme = extendTheme({ config })

function App() {
  return (
      <BrowserRouter>
        <ChakraProvider theme={theme}>
          <Switch>
            <Route>
              <HomePage/>
            </Route>
          </Switch>
        </ChakraProvider>
      </BrowserRouter>
  );
}

export default App;
