import React from 'react';
import {Stack, HStack, Heading, Text, Button, Link, Flex} from '@chakra-ui/react';
import { ExternalLinkIcon, EmailIcon, LinkIcon } from '@chakra-ui/icons'


function HomePage(props) {

  const bgColor = "gray.900"
  const textColor = "gray.500";
  const textAlternateColor = "gray.600";

  return (
      <Stack bg={bgColor} minHeight={"100vh"} align={"center"} justify={"center"} padding={[5, 5, 10, 10]} spacing={10} overflowX={"hidden"}>
        <Stack flex={1} spacing={[10, 10, 20, 20]} align={"center"} justify={"center"}>
          <Stack bg={bgColor} align={"center"} justify={""} spacing={2}>
            <Heading as={"h1"} size={"3xl"} color={textColor}>mahfuz hannan.</Heading>
            <Text size={"md"} color={textColor}>software developer</Text>
            <Link href="mailto:mahfuzhannan@outlook.com" isExternal color={textAlternateColor}>
              <EmailIcon mx={"2px"}/> mahfuzhannan@outlook.com
            </Link>
          </Stack>

          <Stack bg={bgColor} align={"center"} justify={""} spacing={4}>
            <Heading as={"h1"} size={"lg"} color={textAlternateColor}>Things I've worked on</Heading>
            <Link href="https://fajr.io" isExternal color={textAlternateColor}>
              Fajr: Wake-up Calls & Quran <ExternalLinkIcon mx="2px" />
            </Link>
            <Link href="https://halalmunch.com" isExternal color={textAlternateColor}>
              Halal Munch <ExternalLinkIcon mx="2px" />
            </Link>
            <Link href="https://nahw.io" isExternal color={textAlternateColor}>
              Nahw.io <ExternalLinkIcon mx="2px" />
            </Link>
            <Link href="https://salahspace.app" isExternal color={textAlternateColor}>
              Salah Space <ExternalLinkIcon mx="2px" />
            </Link>
            <Link href="https://shareiftar.org" isExternal color={textAlternateColor}>
              Share Iftar <ExternalLinkIcon mx="2px" />
            </Link>
          </Stack>
        </Stack>
        <Stack spacing={4} align={"center"} justify={"center"}>
          <HStack spacing={6} shouldWrapChildren wrap={"wrap"}>
            <Link href="https://linkedin.com/mahfuzhannan" isExternal color={textAlternateColor}>
              <LinkIcon mx="2px" /> LinkedIn
            </Link>
            <Link href="https://instagram.com/mahfuzhannan" isExternal color={textAlternateColor}>
              <LinkIcon mx="2px" /> Instagram
            </Link>
            <Link href="https://twitter.com/mahfuzhannan" isExternal color={textAlternateColor}>
              <LinkIcon mx="2px" /> Twitter
            </Link>
          </HStack>
        </Stack>

      </Stack>
  )
}

export default HomePage;
